<script>
    import animationData from "@/components/widgets/hzomhqxz.json";
    import Lottie from "@/components/widgets/lottie.vue";
    import { logout } from "@/api/auth";
    import myLoader from "../../components/myLoader.vue";

    export default {
        data() {
            return {
                defaultOptions: {
                    animationData: animationData
                },
                isLoader:false,
            }
        },
        components: {
            lottie: Lottie,
            myLoader,
        },
        mounted() {
          this.setLogout();
        },

        methods: {  
          async setLogout() {
            this.isLoader=true;
            const result = await logout({
          })
          this.messageError='';
          this.isLoader=false;
          if (result.state == false) {
            this.authError = true;
            if(typeof result.info.message==='object'){
              result.info.message.map((item) => {
                this.messageError=this.messageError+item+' \n';
              });
            }else{   
              this.messageError=result.info.message;
            }
            return ;
          }else{
            this.authError = false;
            // inicia aplicación
            
          }
        }  
      }
    }
</script>

<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-none"></div>

            <div class="shape">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
                  1440,40L1440 140L0 140z"></path>
              </svg>
            </div>
            <Particles id="tsparticles" :options="{
              particles: {
                number: {
                  value: 90,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: '#ffffff',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.8,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0,
                    sync: false,
                  },
                },
                size: {
                  value: 4,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 4,
                    size_min: 0.2,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: '#ffffff',
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'out',
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'bubble',
                  },
                  onclick: {
                    enable: true,
                    mode: 'repulse',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 4,
                    duration: 2,
                    opacity: 0.8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              config_demo: {
                hide_card: false,
                background_color: '#b61924',
                background_image: '',
                background_position: '50% 50%',
                background_repeat: 'no-repeat',
                background_size: 'cover',
              },
            }" />
        </div>
        <div v-if="isLoader" style="position:absolute; top: 35%; 
          left:40%;
          z-index: 9999;  "  >
            <myLoader /> 
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card mt-4">
                  <div class="card-body p-4 text-center">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="text-center mt-sm-1 mb-4 text-white-40">
                          <div>
                            <img src="@/assets/images/NSLogoLight.png" alt="" height="60" />
                          </div>
                          <p class="mt-3 fs-25 fw-medium">
                            Tecnología aplicada al transporte y la lógistica 
                          </p>
                        </div>
                      </div>
                    </div>
                    <lottie colors="primary:#405189,secondary:#08a88a" :options="defaultOptions"
                        :height="180" :width="180" />
                    <div class="mt-4 pt-2">
                      <h5>Su acceso a terminado</h5>
                      <p class="text-muted">Gracias por utilizar <span class="fw-semibold">DELIVERY NS</span>
                         Gestión Inteligente de Tráfico</p>
                      <div class="mt-4">
                        <router-link to="/login" class="btn btn-success w-100">Ingresar
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                          <p class="mb-0 text-muted">
                            &copy; {{ new Date().getFullYear() }} NEXT STEP COLOMBIA 
                            <i class="mdi mdi-heart text-danger"></i> Siempre Adelante
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>